var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('a',{staticClass:"ancor",attrs:{"id":"spy-get-in-touch"}}),_c('section',{staticClass:"section-contact"},[_c('div',{staticClass:"container-fluid bg-grey-10 custom-pb"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row",class:{
            'q-gutter-x-xl': _vm.$q.screen.md || _vm.$q.screen.gt.md,
          }},[_c('div',{staticClass:"col-lg-3 col-md-3 col-12",class:{
              'q-mt-lg': _vm.$q.screen.lt.md,
            }},[_c('div',{staticClass:"item"},[_vm._m(0),_c('q-list',{staticStyle:{"max-width":"318px"},attrs:{"dense":"","dark":""}},[_c('q-item',{directives:[{name:"ripple",rawName:"v-ripple"}],attrs:{"clickable":""},on:{"click":function($event){return _vm.navigateToHome()}}},[_c('q-item-section',[_c('q-item-label',[_vm._v("गृह")])],1)],1),_c('q-item',{directives:[{name:"ripple",rawName:"v-ripple"}],attrs:{"clickable":""},on:{"click":function($event){return _vm.navigateToContact()}}},[_c('q-item-section',[_c('q-item-label',[_vm._v("सम्पर्क")])],1)],1),_c('q-item',{directives:[{name:"ripple",rawName:"v-ripple"}],attrs:{"clickable":""},on:{"click":function($event){return _vm.navigateToAbout()}}},[_c('q-item-section',[_c('q-item-label',[_vm._v("जानकारी")])],1)],1)],1)],1)]),_c('div',{staticClass:"col-12 col-lg-4 col-md-4",class:{
              'q-mt-lg': _vm.$q.screen.lt.md,
            }},[_vm._m(1)]),_c('div',{staticClass:"col-12 col-md-2 col-lg-3",class:{
              'q-mt-lg': _vm.$q.screen.lt.md,
            }},[_vm._m(2)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',{staticClass:"item__header"},[_c('h3',{staticClass:"item__title"},[_vm._v("मेनु")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"item"},[_c('header',{staticClass:"item__header"},[_c('h3',{staticClass:"item__title"},[_vm._v("सम्पर्क")])]),_c('div',{staticClass:"company-contacts"},[_c('div',{staticClass:"col-lg-6",staticStyle:{"margin-left":"35px"}},[_c('address',[_c('span',{staticClass:"q-mt-sm"},[_c('i',{staticClass:"fas fa-location-arrow"}),_vm._v(" तुलसीपुर -६, दाङ ")]),_c('br'),_c('span',{staticClass:"q-mt-md"},[_c('i',{staticClass:"fas fa-at"}),_vm._v(" dangkrishiupaj@gmail.com"),_c('br')])])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"item",staticStyle:{"max-width":"100%"}},[_c('header',{staticClass:"item__header"},[_c('h3',{staticClass:"item__title"},[_vm._v("लिंकहरु")])]),_c('div',{staticClass:"social-btns q-mb-md"},[_c('div',{staticClass:"social-btns__inner"},[_c('a',{staticClass:"fontello-facebook",attrs:{"href":"#","target":"_blank"}}),_c('a',{staticClass:"fontello-youtube",attrs:{"href":"#","target":"_blank"}})])]),_c('strong',[_vm._v("Powered by")]),_vm._v(" "),_c('br'),_c('a',{attrs:{"href":"http://www.geokrishi.farm/","target":"_blank"}},[_c('span',{staticClass:"company-img",staticStyle:{"width":"fit-content"}},[_c('img',{staticStyle:{"width":"123px"},attrs:{"src":require("@/assets/img/butwal/geokrishi.png"),"alt":""}})])]),_c('a',{attrs:{"href":"https://www.samuhikbazar.com/en/","target":"_blank"}},[_c('span',{staticClass:"company-img q-mt-lg",staticStyle:{"width":"fit-content","background-color":"white","padding":"9px"}},[_c('img',{staticStyle:{"width":"110px"},attrs:{"src":require("@/assets/img/butwal/geokrishi_marketplace.png"),"alt":""}})])])])
}]

export { render, staticRenderFns }