import { postApi, getApi, deleteApi, patchApi } from "@/store/api/api";
import { Action } from "@/store/actionType";
import { MMCMembers } from "../model/mmc_members";
// import { PaginatedTransportList, Transport } from "../model/transport";

export default new (class mmc_listAPI {
  public async GetMMCMembers(query: any): Promise<any> {
    const url = `${Action.MMCMembers}`;
    const response = await getApi(url, query);
    return response;
  }

  public async CreateMMCMember(param: any): Promise<any> {
    const url = `${Action.MMCMembers}`;
    const response = await postApi(url, param, {
      "Content-Type": "multipart/form-data",
    });
    return response as MMCMembers;
  }

  public async editMMCMember(data: FormData, id: number): Promise<any> {
    const url = `${Action.MMCMembers}/${id}`;
    const response = await patchApi(url, data, {
      "Content-Type": "multipart/form-data",
    });
    return response as MMCMembers;
  }

  public async getMMCMemberById(mmc_id: any): Promise<any> {
    const url = `${Action.MMCMembers}/${mmc_id}`;
    const response = await getApi(url);
    return response;
  }

  public async deleteMmcMember(id: number): Promise<any> {
    const url = `${Action.MMCMembers}`;
    console.log("Deleting", url);
    const response = await deleteApi(url, id);

    return response;
  }
  public async getPaginatedMMCMembers(query: any): Promise<any> {
    const url = `${Action.PaginatedMmcMember}`;
    const response = await getApi(url, query);
    return response;
  }
  public async getMmcDesignation() {
    const response = await getApi(Action.Designation);
    return response;
  }
})();
